export const coreRoutes = {
	root: '/client',
	auth: '/client/auth',
	carePlan: '/client/care-plan',
	jobPosts: '/client/job-posts',
	offers: '/client/offers',
	dashboard: '/client/dashboard',
	chat: '/client/chat',
	documents: '/client/documents',
	payments: '/client/payments',
	shifts: '/client/shifts',
	profiles: '/client/profiles',
	tos: '/client/terms-of-service',
	privacyPolicy: '/client/privacy-policy',
};
