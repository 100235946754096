import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Calendar from '../../../../../corev2/Calendar';
import Text from '../../../../../corev2/Text';
import { Select } from '../../../../../corev2/Forms';
import Loader from '../../../../../corev2/Loader';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import PageHeading from '../../../core/components/PageHeading';
import AddScheduleForm from '../../components/AddScheduleForm';
import CancelShiftForm from '../../components/CancelShiftForm';
import DisputeShiftForm from '../../components/DisputeShiftForm';
import ReassignShiftForm from '../../components/ReassignShiftForm';
import DeleteShiftForm from '../../components/DeleteShiftForm';

import { useAuth } from '../../../core/hooks/useAuth';

import { useAddScheduleToCarePlanMutation } from '../../api/mutations/useAddScheduleToCarePlanMutation';
import { useFetchCarePlanShiftsQuery } from '../../../core/api/queries/useFetchCarePlanShiftsQuery';
import { useCancelShiftMutation } from '../../api/mutations/useCancelShiftMutation';
import { useDisputeShiftMutation } from '../../api/mutations/useDisputeShiftMutation';
import { useReassignShiftMutation } from '../../api/mutations/useReassignShiftMutation';
import { useDeleteShiftMutation } from '../../api/mutations/useDeleteShiftMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import {
	formatIncomingShift,
	generateEvents,
	calendarEventStyleGenerator,
} from '../../../core/lib';

import {
	StyledJobSchedulePage,
	StyledPageHeadingContainer,
	StyledPageHeadingWrapper,
	StyledContainer,
	StyledCalendarHeader,
	StyledNavContainer,
	StyledHeadingContainer,
	StyledCalendarheading,
	StyledCalendarSelectGroup,
	StyledBlueSelect,
} from './styles';

const CarePlanSchedulePage = () => {
	const [serverError, setServerError] = useState('');
	const [events, setEvents] = useState([]);
	const [view, setView] = useState('month');
	const [currentDate, setCurrentDate] = useState(new Date());
	const [isCalendarPopupOpen, setIsCalendarPopupOpen] = useState(false);
	const [isCancelShiftFormOpen, setIsCancelShiftFormOpen] = useState(false);
	const [isDisputeShiftFormOpen, setIsDisputeShiftFormOpen] = useState(false);
	const [isReassignShiftFormOpen, setIsReassignShiftFormOpen] = useState(false);
	const [isDeleteShiftFormOpen, setIsDeleteShiftFormOpen] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editingEvent, setEditingEvent] = useState(null);

	const { authUser } = useAuth();
	const history = useHistory();

	const addScheduleToCarePlan = useAddScheduleToCarePlanMutation();
	const reassignShift = useReassignShiftMutation();
	const disputeShift = useDisputeShiftMutation();
	const deleteShift = useDeleteShiftMutation();

	const {
		data: shifts,
		isLoading: isLoadingShifts,
		refetch: refetchShifts,
	} = useFetchCarePlanShiftsQuery();

	const cancelShift = useCancelShiftMutation();

	useEffect(() => {
		if (shifts) setEvents(generateEvents(shifts));
	}, [shifts]);

	const addScheduleToCarePlanHandler = async (
		startDate,
		startTime,
		endDate,
		endTime,
		repeatFrequency,
		daysOfWeek,
		shifts
	) => {
		try {
			const shiftAdded = await addScheduleToCarePlan.mutateAsync({
				startDate,
				startTime,
				endDate,
				endTime,
				repeatFrequency,
				daysOfWeek,
				carePlanId: authUser.carePlan.id,
				shifts,
			});

			refetchShifts();
			Notification(notifications.carePlan.scheduleAdded);
			setIsCalendarPopupOpen(false);
			// console.log('shiftAdded', shiftAdded);
			// history.push(`/client/offers?job_post_id=${shiftAdded.id}`);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Add schedule to job post failed', error);
			return false;
		}
	};

	const reassignShiftHandler = async (shiftId) => {
		try {
			await reassignShift.mutateAsync({ shiftId });

			refetchShifts();
			setIsReassignShiftFormOpen(false);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Cancel shift by client failed', error);
		}
	};

	const disputeShiftHandler = async (disputeReason, shiftId) => {
		try {
			await disputeShift.mutateAsync({ disputeReason, shiftId });

			refetchShifts();
			setIsDisputeShiftFormOpen(false);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Dispute shift by client failed', error);
		}
	};

	const cancelShiftHandler = async (shiftId) => {
		try {
			await cancelShift.mutateAsync({ shiftId });

			refetchShifts();
			setIsCancelShiftFormOpen(false);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Cancel shift by client failed', error);
		}
	};

	const deleteShiftHandler = async (shiftId) => {
		try {
			await deleteShift.mutateAsync({ shiftId });

			refetchShifts();
			setIsDeleteShiftFormOpen(false);
		} catch (error) {
			console.error('Delete shift by client failed', error);
			setServerError(error[0].message);
		}
	};

	const editShiftHandler = async (params) => {};

	const handleNavigateLeft = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).subtract(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).subtract(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).subtract(1, 'day').toDate());
		}
	};

	const handleNavigateRight = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).add(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).add(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).add(1, 'day').toDate());
		}
	};

	const handleViewChange = (event) => setView(event.target.value);

	const openPopupHandler = (date = null) => {
		setIsCalendarPopupOpen(true);
		if (view === 'day' && date) {
			setEditingEvent({ start: date, end: date });
		} else {
			setEditingEvent(null);
		}
	};

	const closePopupHandler = () => {
		setEditMode(false);
		setIsCalendarPopupOpen(false);
	};

	const cancelShiftModalHandler = () => {
		setIsCancelShiftFormOpen(!isCancelShiftFormOpen);
	};

	const disputeShiftModalHandler = () => {
		setIsDisputeShiftFormOpen(!isDisputeShiftFormOpen);
	};

	const reassignShiftModalHandler = () => {
		setIsReassignShiftFormOpen(!isReassignShiftFormOpen);
	};

	const deleteShiftModalHandler = () => {
		setIsDeleteShiftFormOpen(!isDeleteShiftFormOpen);
	};

	const handleEventClick = (event) => {
		const isPastEvent = moment(event.start).isBefore(moment());

		if (
			isPastEvent &&
			event.resource.shift.status !== 'paid' &&
			event.resource.shift.status !== 'submitted'
		) {
			return;
		}

		const { shift } = event.resource;

		switch (shift.status) {
			case 'pending':
				// if you wanna change your schedules (which means changing all the list of shifts) then all front-end
				// logic will go here.
				// setIsCalendarPopupOpen(true);
				// setEditingEvent(event);
				// setEditMode(true);
				// break;
				setIsDeleteShiftFormOpen(true);
				setEditingEvent(event);
				break;

			case 'assigned':
				setIsCancelShiftFormOpen(true);
				setEditingEvent(event);
				break;

			case 'paid':
				setIsDisputeShiftFormOpen(true);
				setEditingEvent(event);
				break;

			case 'submitted':
				setIsDisputeShiftFormOpen(true);
				setEditingEvent(event);
				break;

			case 'canceled':
				setIsReassignShiftFormOpen(true);
				setEditingEvent(event);
				break;
		}
	};

	if (isLoadingShifts) {
		return <Loader />;
	}

	return (
		<StyledJobSchedulePage>
			<StyledPageHeadingContainer>
				<StyledPageHeadingWrapper>
					<PageHeading>Schedule for {authUser.name}</PageHeading>
				</StyledPageHeadingWrapper>
				<Text>
					To set a schedule for your care plan and to receive offers, click on the "Add
					Schedule" button. Fill in the required details such as start date, start time,
					end date, end time, repeat frequency, and days of the week. Once submitted, the
					schedule will be added to your care plan, and Care Pros will be able to send
					offers based on the specified schedule and their availability.
				</Text>
			</StyledPageHeadingContainer>

			{isCalendarPopupOpen && (
				<AddScheduleForm
					editMode={editMode}
					event={editingEvent}
					closePopupHandler={closePopupHandler}
					addScheduleToCarePlanHandler={addScheduleToCarePlanHandler}
					// updateEventHandler={handleUpdateEvent}
					// deleteEventHandler={() => {}}
					selectedDate={view === 'day' ? currentDate : null}
					serverError={serverError}
				/>
			)}

			{isCancelShiftFormOpen && (
				<CancelShiftForm
					shift={formatIncomingShift(editingEvent.resource.shift)}
					carePro={editingEvent.resource.shift.provider}
					cancelShiftModalHandler={cancelShiftModalHandler}
					cancelShiftHandler={cancelShiftHandler}
				/>
			)}

			{isDisputeShiftFormOpen && (
				<DisputeShiftForm
					shift={formatIncomingShift(editingEvent.resource.shift)}
					carePro={editingEvent.resource.shift.provider}
					disputeShiftHandler={disputeShiftHandler}
					disputeShiftModalHandler={disputeShiftModalHandler}
				/>
			)}

			{isReassignShiftFormOpen && (
				<ReassignShiftForm
					shift={formatIncomingShift(editingEvent.resource.shift)}
					reassignShiftHandler={reassignShiftHandler}
					reassignShiftModalHandler={reassignShiftModalHandler}
				/>
			)}

			{isDeleteShiftFormOpen && (
				<DeleteShiftForm
					shift={formatIncomingShift(editingEvent.resource.shift)}
					deleteShiftHandler={deleteShiftHandler}
					deleteShiftModalHandler={deleteShiftModalHandler}
				/>
			)}

			<StyledContainer>
				<StyledCalendarHeader>
					<StyledNavContainer>
						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronLeft}
								onClick={handleNavigateLeft}
							/>
						</StyledBlueSelect>

						<StyledCalendarheading>
							{view === 'day'
								? moment(currentDate).format('DD MMMM YYYY')
								: moment(currentDate).format('MMMM YYYY')}
						</StyledCalendarheading>

						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronRight}
								onClick={handleNavigateRight}
							/>
						</StyledBlueSelect>
					</StyledNavContainer>

					<StyledHeadingContainer>
						<StyledCalendarSelectGroup>
							<Select value={view} onChange={handleViewChange}>
								<option value='month'>Month</option>
								<option value='week'>Week</option>
								<option value='day'>Day</option>
								<option value='agenda'>Agenda</option>
							</Select>
						</StyledCalendarSelectGroup>

						<PrimaryButton size='small' onClick={() => openPopupHandler(currentDate)}>
							Add Schedule
						</PrimaryButton>
					</StyledHeadingContainer>
				</StyledCalendarHeader>

				<Calendar
					events={events}
					currentDate={currentDate}
					view={view}
					onNavigate={setCurrentDate}
					onView={setView}
					onSelectEvent={handleEventClick}
					eventStyleGenerator={calendarEventStyleGenerator}
				/>
			</StyledContainer>
		</StyledJobSchedulePage>
	);
};

export default CarePlanSchedulePage;
