import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import PersonalStripeForm from '../../../components/PersonalStripeForm';

import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';
import { useUpdateCareProStripeInformationMutation } from '../../../../core/api/mutations/useUpdateCareProStripeInformationMutation';

import { authRoutes } from '../../../routes/constants';

import { StyledPersonalInfoPage } from './styles';

const PersonalInfoPage = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');
	const [loading, setLoading] = useState(false);

	const { stripeOnboardingState, mutateStripeOnboardingState, resetStripeOnboardingState } =
		useStripeOnboarding();

	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();

	const presonalStripeInfoHandler = async (values) => {
		try {
			setLoading(true);
			await updateCareProStripeInformation.mutateAsync({
				dob_day: values.day,
				dob_month: values.month,
				dob_year: values.year,
				id_number: values.ssn,
				ssn_last_4: values.ssn.slice(-4),
			});

			mutateStripeOnboardingState('personal', {
				...stripeOnboardingState.personal,
				data: {
					day: values.day,
					month: values.month,
					year: values.year,
					ssn: values.year,
				},
				isCompleted: true,
			});

			// resetStripeOnboardingState();
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Stripe personal info failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.stripe.payouts);
	};

	return (
		<StyledPersonalInfoPage>
			<PageHeading>Your DOB & SSN</PageHeading>

			<PersonalStripeForm
				initialValues={{
					month: stripeOnboardingState.personal.data.month,
					day: stripeOnboardingState.personal.data.day,
					year: stripeOnboardingState.personal.data.year,
					ssn: stripeOnboardingState.personal.data.ssn,
				}}
				presonalStripeInfoHandler={presonalStripeInfoHandler}
				serverError={serverError}
			/>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledPersonalInfoPage>
	);
};

export default PersonalInfoPage;
